import SvgIconArrowLeft from './IconArrowLeft';
import SvgIconArrowRight from './IconArrowRight';
import SvgIconCheck from './IconCheck';
import SvgIconClose from './IconClose';
import SvgIconError from './IconError';
import SvgIconNode from './IconNode';
import SvgIconPlus from './IconPlus';
import SvgIllustrationAcceptance from './IllustrationAcceptance';
import SvgIllustrationAlreadySubmitted from './IllustrationAlreadySubmitted';
import SvgIllustrationCuriosity from './IllustrationCuriosity';
import SvgIllustrationEvidenceMatch from './IllustrationEvidenceMatch';
import SvgIllustrationFreedom from './IllustrationFreedom';
import SvgIllustrationGoal from './IllustrationGoal';
import SvgIllustrationHonor from './IllustrationHonor';
import SvgIllustrationMastery from './IllustrationMastery';
import SvgIllustrationOrder from './IllustrationOrder';
import SvgIllustrationPower from './IllustrationPower';
import SvgIllustrationRelatedness from './IllustrationRelatedness';
import SvgIllustrationStatus from './IllustrationStatus';
import SvgLogo from './Logo';
import SvgRadar from './Radar';

export {
    SvgLogo,
    SvgIconArrowRight,
    SvgIconArrowLeft,
    SvgIconNode,
    SvgIllustrationStatus,
    SvgIllustrationRelatedness,
    SvgIllustrationPower,
    SvgIllustrationOrder,
    SvgIllustrationMastery,
    SvgIllustrationHonor,
    SvgIllustrationGoal,
    SvgIllustrationFreedom,
    SvgIllustrationCuriosity,
    SvgIllustrationAcceptance,
    SvgIconPlus,
    SvgIconCheck,
    SvgRadar,
    SvgIllustrationEvidenceMatch,
    SvgIllustrationAlreadySubmitted,
    SvgIconClose,
    SvgIconError
};
