import { Formik } from "formik";
import React from "react";
import * as yup from 'yup';

function Form({ initialValues = {}, children, schema, onSubmit, ...props }) {
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            validate={values => {
                const errors = {};

                try {
                    schema.validateSync(values, {
                        abortEarly: false
                    });
                } catch (err) {
                    if (err instanceof yup.ValidationError) {
                        err.inner.forEach(error => {
                            errors[error.path] = error.message;
                        });
                    }
                }

                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                !!onSubmit && onSubmit(values);
            }}
            {...props}
        >
            {children}
        </Formik>
    );
}

export default React.memo(Form);