import * as React from "react"

function IllustrationGoal(props) {
    return (
        <svg
            width={67}
            height={81}
            viewBox="0 0 67 81"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M62.027 14.705a4.663 4.663 0 100-9.327 4.663 4.663 0 000 9.327z"
                fill="#E31C46"
            />
            <path
                d="M60.384 73.285l-26.127-46.91c-1.883-3.377-6.753-3.337-8.58.073L.584 73.358c-1.74 3.257.617 7.19 4.31 7.19h51.22c3.727.004 6.083-4.006 4.27-7.263z"
                fill="#4CD6C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.93.985A1.595 1.595 0 0111.404 0h18.997c.88 0 1.595.714 1.595 1.595V27.82a1.595 1.595 0 01-3.19 0V3.19h-13.55l4.346 4.347a1.595 1.595 0 010 2.256l-4.166 4.164h9.408a1.595 1.595 0 110 3.19h-13.26a1.595 1.595 0 01-1.128-2.723l5.762-5.76-5.942-5.94A1.595 1.595 0 019.93.983z"
                fill="#412ECC"
            />
        </svg>
    )
}

export default IllustrationGoal
