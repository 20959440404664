import React from 'react';
import styled from 'styled-components';
import { Button, Text } from '../../components';
import colors from '../../utils/colors';
function Finish({ }) {
    return (
        <Wrapper>
            <img src={`${process.env.PUBLIC_URL}/img/illustrationEM.png`} />
            <Text font="Aeonik-Bold" maxWidth="452px" align="center" color={colors.purple02} size={12} height={16} style={{ marginTop: 32, marginBottom: 12 }}>EVIDENCE MATCH</Text>
            <Text maxWidth="452px" align="center" color={colors.grey01} size={32} height={40} style={{ marginBottom: 12 }}>Your awnsers to the evidence match was sent successfully</Text>
            <Text maxWidth="452px" align="center" color={colors.grey03} size={14} height={24} style={{ marginBottom: 32 }}>Our team will contact as soon as possible to talk about your results, stay aware of your email.</Text>
            <Button width={176} color={colors.purple02} onClick={() => window.location.href = "https://evidencematch.com"}>
                <Text
                    color={"white"}
                    size={16}
                    height={24}
                >
                    Go to evidencematch.com
                </Text>
            </Button>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: white;
`;

export default Finish;