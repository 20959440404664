import React, { useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import colors from '../../utils/colors';
import Block from './components/Block';
import Form from './components/Form';
import FormGroup from './components/FormGroup';
import Input from './components/Input';
import ListEducation from './components/Inputs/ListEducation';
import ListOtherCourses from './components/Inputs/ListOtherCourses';
import ListProfessionalExperience from './components/Inputs/ListProfessionalExperience';
import ListTeachingExperience from './components/Inputs/ListTeachingExperience';
import ListTechnicalSkills from './components/Inputs/ListTechnicalSkills';
import TextInput from './components/Inputs/TextInput';
import Portlet from './components/Portlet';
import Text from './components/Text';

const Cv = React.forwardRef(({ onSubmit }, ref) => {
    const formRef = useRef(null);
    const valuesRef = useRef(null);

    useImperativeHandle(ref, () => ({
        validate: () => {
            formRef.current.handleSubmit();
        },
        getValue: () => {
            return valuesRef.current;
        }
    }), []);

    return (
        <Block width="100%" height="100%" color="white">
            <Wrapper>
                <Block style={{ overflowY: "scroll" }}>
                    <Content center>
                        <Block width={964} center>
                            <Text size={32} height={40} width={452} color={colors.grey01} center>Please write a little about yourself and your experience</Text>
                            <Block width="100%" mt={72}>
                                <Form
                                    innerRef={formRef}
                                    schema={yup.object().shape({
                                        personalName: yup.string().required(),
                                        contactsEmail: yup.string().email().required()
                                    })}
                                    onSubmit={(values) => {
                                        valuesRef.current = values;
                                        !!onSubmit && onSubmit();
                                    }}
                                >
                                    {({
                                        errors,
                                        handleSubmit,
                                    }) => {
                                        return (
                                            <form onSubmit={handleSubmit} autoComplete="off">
                                                <Portlet title="General data">
                                                    <FormGroup>
                                                        <Block width={688}>
                                                            <Input Component={TextInput} error={errors.personalName} name="personalName" label="NAME" placeholder="Complete name" />
                                                        </Block>
                                                        <Block row>
                                                            <Block width={330} mr={20}>
                                                                <Input Component={TextInput} error={errors.personalNationality} name="personalNationality" label="NATIONALITY" placeholder="Nationality" />
                                                            </Block>
                                                            <Block width={216} mr={20}>
                                                                <Input Component={TextInput} error={errors.personalBirthdate} name="personalBirthdate" label="BIRTHDATE" placeholder="DD / MM / AAAA" type="date" />
                                                            </Block>
                                                            <Block width={330}>
                                                                <Input Component={TextInput} error={errors.personalGender} name="personalGender" label="GENDER" placeholder="Gender" />
                                                            </Block>
                                                        </Block>
                                                        <Block width={688}>
                                                            <Input Component={TextInput} error={errors.contactsLocation} name="contactsLocation" label="LOCATION" placeholder="City and country where is based" />
                                                        </Block>
                                                    </FormGroup>
                                                </Portlet>
                                                <Portlet title="Contacts">
                                                    <FormGroup>
                                                        <Block row>
                                                            <Block width={448} mr={20}>
                                                                <Input Component={TextInput} error={errors.contactsEmail} name="contactsEmail" label="EMAIL" placeholder="Professional email" />
                                                            </Block>
                                                            <Block width={334}>
                                                                <Input Component={TextInput} error={errors.contactsPhone} name="contactsPhone" label="PHONE" placeholder="Professional phone" />
                                                            </Block>
                                                        </Block>
                                                    </FormGroup>
                                                </Portlet>
                                                <Block mt={48}>
                                                    <Input Component={ListEducation} error={errors.education} name="education" />
                                                </Block>
                                                <Block mt={48}>
                                                    <Input Component={ListProfessionalExperience} error={errors.professionalExperience} name="professionalExperience" />
                                                </Block>
                                                <Block mt={48}>
                                                    <Input Component={ListTeachingExperience} error={errors.teachingExperience} name="teachingExperience" />
                                                </Block>
                                                <Block mt={48}>
                                                    <Input Component={ListOtherCourses} error={errors.otherCourses} name="otherCourses" />
                                                </Block>
                                                <Block mt={48}>
                                                    <Input Component={ListTechnicalSkills} error={errors.technicalSkills} name="technicalSkills" />
                                                </Block>
                                            </form>
                                        )
                                    }}
                                </Form>
                            </Block>
                        </Block>
                    </Content>
                </Block>
            </Wrapper>
        </Block>
    );
});

const Wrapper = styled(Block)`
    position: relative;
    height: calc(100% - 172px);
    margin-top: 64px;
    width: 100%;
`;

const Content = styled(Block)`
    background: ${colors.white01};
    padding: 0 0 54px 0;
    display: flex;
    flex-flow: column;
`;

export default Cv;