import * as React from "react"

function IconArrowRight({ color = "#fff", ...props }) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11.25 6.25L15 10l-3.75 3.75M15 10H3.75"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default IconArrowRight
