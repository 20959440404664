import React, { useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import { SvgIconCheck, SvgIconPlus } from "../../assets/svgs";
import { Text } from "../../components";
import colors from '../../utils/colors';

const VideoUpload = React.forwardRef(({ }, ref) => {
    const inputRef = useRef(null);
    const [file, setFile] = useState(null);

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return inputRef.current.files[0];
        }
    }), []);

    return (
        <Wrapper>
            <input ref={inputRef} type="file" accept="video/*" style={{ display: "none" }} onChange={(e) => setFile(e.target.files[0])} />
            <Text maxWidth="452px" align="center" color={colors.grey01} size={32} height={40} style={{ marginTop: 84, marginBottom: 12 }}>How much can you tell us about yourself in 1 or 2 minutes maximum?</Text>
            <Text maxWidth="452px" align="center" color={colors.grey03} size={14} height={28}>Put on your best smile, film yourself, and tells us why you're perfect for the job.</Text>
            <Center onClick={() => inputRef.current.click()}>
                <Circle>
                    {file ? <SvgIconCheck /> : <SvgIconPlus />}
                </Circle>
                <Text color={colors.grey01} size={16} height={24}>{file ? file.name : "Upload Video"}</Text>
            </Center>
        </Wrapper>
    );
});

const Wrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: column;
    background-color: white;
`;

const Center = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 160px;
`;

const Circle = styled.div`
    width: 120px;
    height: 120px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    background-color: ${colors.grey01};
    cursor: pointer;
`;

export default VideoUpload;