import React from "react";
import Block from "../Block";

export default function FormGroup(
    {
        children
    }
) {
    return (
        <Block>
            {!!children && !Array.isArray(children) ? children : children.map((child, index) => {
                return (<Block key={index} mb={children.length - 1 === index ? 0 : 20}>{child}</Block>);
            })}
        </Block>
    );
}