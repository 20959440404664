import React from "react";
import styled from "styled-components";
import { SvgIconClose } from "../../../../../assets/svgs";
import colors from "../../../../../utils/colors";
import Block from "../../Block";
import Button from "../../Button";
import FieldArray from "../../FieldArray";
import FormGroup from "../../FormGroup";
import Input from "../../Input";
import Portlet from "../../Portlet";
import Text from "../../Text";
import TextAreaInput from "../TextAreaInput";

export default function ListTeachingExperience({ value = [] }) {
    return (
        <FieldArray
            name={"teachingExperience"}
            render={(arrayHelpers) => {
                return (
                    <Block>
                        <Text size={24} height={32} color={colors.grey01} mb={24}>Teaching experience / freelancer</Text>
                        {value.map((_item, index) => {
                            return (
                                <Portlet
                                    key={index}
                                >
                                    <ButtonDelete center middle onClick={() => arrayHelpers.remove(index)}>
                                        <SvgIconClose />
                                    </ButtonDelete>
                                    <FormGroup>
                                        <Block width={822}>
                                            <Input Component={TextAreaInput} name={`teachingExperience[${index}].activities`} label="EXPERIENCE" placeholder="Description of the experience" />
                                        </Block>
                                    </FormGroup>
                                </Portlet>
                            );
                        })}
                        <Block>
                            <Button type="button" width="216px" label="Add experience" color={colors.grey01} onClick={() => arrayHelpers.insert(value.length, {})} />
                        </Block>
                    </Block>
                );
            }}
        />
    );
}

const ButtonDelete = styled(Block)`
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    right: 12px;
    border-radius: 12px;
    background: ${colors.grey01};
    cursor: pointer;
`;