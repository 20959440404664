import styled, { css } from "styled-components";

const Button = styled.div`
    ${({ width }) => !!width && css`
        width: ${width}px;
    `}

    ${({ minWidth }) => !!minWidth && css`
        min-width: ${minWidth}px;
    `}

    ${({ color }) => !!color && css`
        background-color: ${color};

        ${color === "white" && css`
            box-shadow: 0px 4px 16px rgba(102, 102, 102, 0.16);
        `}
    `}
    
    cursor: pointer;
    border-radius: 26px;
    border: none;
    height: 52px;
    padding: ${({ rounded }) => rounded ? "16px" : "14px 24px"};
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
`;

export default Button;