import React from 'react';
import styled from 'styled-components';
import { SvgIconClose } from '../../assets/svgs';
import colors from '../../utils/colors';

export default function Modal(props) {
    const {
        children,
        isOpen,
        onRequestClose
    } = props;

    return (
        isOpen && <Wrapper>
            <Content >
                <CloseButton onClick={onRequestClose}>
                    <SvgIconClose />
                </CloseButton>
                {children}
            </Content>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Content = styled.div`
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    position: relative;
    width: 728px;
    height: 448px;
    border-radius: 16px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
`;

const CloseButton = styled.div`
    cursor: pointer;
    position: absolute;
    top: 18px;
    right: 24px;
    background-color: ${colors.grey01};
    border-radius: 24px;
    width: 48px;
    height: 48px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
`;