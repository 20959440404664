import React from "react";
import styled from "styled-components";
import colors from "../../../../utils/colors";
import Block from "../Block";
import Text from "../Text";

export default function Portlet(
    {
        width = "100%",
        height = "fit-content",
        title,
        children
    }
) {
    return (
        <Wrapper width={width} color={colors.white01}>
            {!!title && <Text size={24} height={32} color={colors.grey01} mb={24}>{title}</Text>}
            <Content height={height}>
                {children}
            </Content>
        </Wrapper>
    );
}

const Wrapper = styled(Block)`
    position: relative;
    width: ${({ width }) => width};
    margin-bottom: 48px;
    
    :last-of-type {
        margin-bottom: 0;
    }
`;

const Content = styled(Block)`
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    height: ${({ height }) => height};
    padding: 24px;
    border-radius: 16px;
`;