import * as React from "react"

function IllustrationCuriosity(props) {
    return (
        <svg
            width={70}
            height={80}
            viewBox="0 0 70 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M45.356 50.492a4.338 4.338 0 016.099.675l17.2 21.476a4.338 4.338 0 11-6.773 5.424l-17.2-21.476a4.338 4.338 0 01.674-6.099z"
                fill="#4CD6C5"
            />
            <path
                d="M26.608 31.272a4.663 4.663 0 100-9.327 4.663 4.663 0 000 9.327z"
                fill="#E31C46"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.608 3.19C13.675 3.19 3.19 13.675 3.19 26.61c0 12.933 10.485 23.418 23.418 23.418 12.934 0 23.419-10.485 23.419-23.419 0-12.933-10.485-23.418-23.419-23.418zM0 26.61C0 11.912 11.913 0 26.608 0c14.696 0 26.609 11.913 26.609 26.608 0 14.696-11.913 26.609-26.608 26.609C11.912 53.217 0 41.304 0 26.609z"
                fill="#412ECC"
            />
        </svg>
    )
}

export default IllustrationCuriosity
