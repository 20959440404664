import React from 'react';
import styled from 'styled-components';
import { Button, Text } from '../../components';
import colors from '../../utils/colors';

function AlreadySubmitted() {
    return (
        <Wrapper>
            <img src={`${process.env.PUBLIC_URL}/img/illustrationAlreadySubmitted.png`} />
            <Text font="Aeonik-Bold" maxWidth="452px" align="center" color={colors.yellow01} size={12} height={16} style={{ marginTop: 24, marginBottom: 12 }}>EVIDENCE MATCH</Text>
            <Text maxWidth="452px" align="center" color={colors.grey01} size={32} height={40} style={{ marginBottom: 12 }}>Your evidence match was already submitted</Text>
            <Text maxWidth="452px" align="center" color={colors.grey03} size={14} height={24} style={{ marginBottom: 32 }}>If you have any doubts or problems please contact us</Text>
            <Button width={176} color={colors.purple02} onClick={() => window.location.href = "https://evidencematch.com/contacts"}>
                <Text
                    color={"white"}
                    size={16}
                    height={24}
                >
                    Go to contacts
                </Text>
            </Button>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: white;
`;

export default AlreadySubmitted;