export default {
    yellow01: "#EDC683",
    yellow02: "#FFF3DF",
    yellow03: "#E7AD4A",
    red01: "#FF0000",
    grey01: "#000000",
    grey02: "#222527",
    grey03: "#A7A7A7",
    grey04: "#DADADA",
    grey05: "#EBECED",
    grey06: "#F4F4F4",
    pink01: "#E89592",
    white01: "#FFFFFF"
}