import React from 'react';
import styled, { css } from 'styled-components';
import { SvgIconArrowLeft, SvgIconArrowRight } from '../../assets/svgs';
import colors from '../../utils/colors';
import Button from '../Button';
import Text from '../Text';

function Stepper({ currentIndex = 2, screens = [], onClickNext, onClickPrevious, onClickSave }) {
    return (
        <Wrapper>
            <Screens>
                <Screen key={1} index={1} show={1 <= currentIndex}>
                    {screens[1].screen}
                </Screen>
                <Screen key={0} index={0} show={0 == currentIndex}>
                    {screens[0].screen}
                </Screen>
                <Screen key={2} index={2} show={2 <= currentIndex}>
                    {screens[2].screen}
                </Screen>
                <Screen key={3} index={3} show={3 <= currentIndex}>
                    {screens[3].screen}
                </Screen>
                <Screen key={4} index={4} show={4 <= currentIndex}>
                    {screens[4].screen}
                </Screen>
            </Screens>
            <Bottom>
                <Bar>
                    <StepBar length={screens.length} index={currentIndex} />
                    {screens.map((item, index) => (
                        <div key={index}>
                            <Title
                                length={screens.length}
                                index={index}>
                                <Text
                                    color={currentIndex === index ? colors.grey01 : colors.grey03}
                                    size={14}
                                    height={24}
                                >
                                    {item.title}
                                </Text>
                            </Title>
                            <Ball
                                selected={currentIndex >= index}
                                length={screens.length}
                                index={index}
                            />
                        </div>
                    ))}
                </Bar>
                <Right>
                    {currentIndex > 0 && <Button rounded width={52} color={"white"} style={{ marginRight: 8 }} onClick={onClickPrevious}>
                        <SvgIconArrowLeft color={colors.grey01} />
                    </Button>}
                    {currentIndex < screens.length - 1 && <Button width={148} color={colors.grey01} onClick={onClickNext}>
                        <Text
                            color={"white"}
                            size={16}
                            height={24}
                        >
                            Next step
                        </Text>
                        <SvgIconArrowRight style={{ marginLeft: 8 }} />
                    </Button>}
                    {currentIndex === screens.length - 1 && <Button width={148} color={colors.grey01} onClick={onClickSave}>
                        <Text
                            color={"white"}
                            size={16}
                            height={24}
                        >
                            Save
                        </Text>
                    </Button>}
                </Right>
            </Bottom>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    overflow: hidden;
`;

const Screens = styled.div`
    display: flex;
    flex-flow: row;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

const Screen = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 100%;
    height: 100%;
    min-width: 100%;

    ${({ show, index }) => {
        if (index == 0) {
            return css`
                transform: ${show ? "translateX(-100%)" : "translateX(-200%)"};
            `;
        }

        if (index == 1) {
            return css`
                transform: translateX(-100%);
            `;
        }

        return css`
            transform: ${show ? "translateX(-100%)" : "translateX(0%)"};
        `;
    }}

    transition: transform 0.5s linear;
`;

const Bottom = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding-bottom: 36px;
    display: flex;
    align-items: flex-end;;
    justify-content: center;
`;

const Right = styled.div`
    position: absolute;
    bottom: 0;
    right: 22px;
    display: flex;
    flex-flow: row;
    padding-bottom: 28px;
`;

const Bar = styled.div`
    position: relative;
    height: 4px;
    width: 570px;
    border-radius: 4px;
    background-color: ${colors.grey04};
`;

const StepBar = styled.div`
    height: 4px;
    width: ${({ length, index }) => `${(index * (570 / (length - 1)))}px`};
    border-radius: 4px;
    background-color: ${colors.yellow01};
    position: absolute;
    bottom: 0px;
    left: 0;

    transition: width 0.5s linear;
`;

const Title = styled.div`
    position: absolute;
    bottom: 8px;
    left: ${({ length, index }) => {
        if (index === 0) { return `0px` }
        if (index === length - 1) { return `calc(570px + 4px)` }

        return `${(index * (570 / (length - 1)))}px`;
    }};

    transform: ${({ length, index }) => {
        if (index === 0) { return `translateX(0%)` }
        if (index === length - 1) { return `translateX(-100%)` }
        return `translateX(-50%)`;
    }};
`;

const Ball = styled.div`
    height: 4px;
    width: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${({ length, index }) => {
        if (index === 0) { return `0px` }
        if (index === length - 1) { return `570px` }

        return `${(index * (570 / (length - 1))) - 2}px`;
    }};
    border-radius: 2px;
    background-color: ${({ selected }) => selected ? colors.grey01 : colors.grey03};
`;

export default Stepper;