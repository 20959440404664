import React from "react";

const AuthContext = React.createContext(null);

export const Provider = ({ personalMap, movingMotivators, competences, video, children }) => {
    const [currentPersonalMap, setCurrentPersonalMap] = React.useState(personalMap);
    const [currentMovingMotivators, setCurrentMovingMotivators] = React.useState(movingMotivators);
    const [currentCompetences, setCurrentCompetences] = React.useState(competences);
    const [currentVideo, setCurrentVideo] = React.useState(video);

    return (
        <AuthContext.Provider value={{
            currentPersonalMap, setCurrentPersonalMap,
            currentMovingMotivators, setCurrentMovingMotivators,
            currentCompetences, setCurrentCompetences,
            currentVideo, setCurrentVideo
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => React.useContext(AuthContext);