import { Field } from "formik";
import React from "react";

function Input({ name, initialValue, Component, ...props }) {
    return (
        <Field name={name}>
            {({ field: { value = initialValue }, form: { setFieldValue } }) => {
                return (<Component
                    name={name}
                    value={value}
                    onChangeValue={(value) => setFieldValue(name, value)}
                    {...props}
                />)
            }}
        </Field>
    );
}

export default React.memo(Input);