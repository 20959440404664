import * as React from "react"

function IllustrationOrder(props) {
    return (
        <svg
            width={82}
            height={50}
            viewBox="0 0 82 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.345 21.272c0-.881.714-1.595 1.595-1.595h50.177a1.595 1.595 0 110 3.19H16.94c-.88 0-1.595-.714-1.595-1.595zM15.345 47.985c0-.88.714-1.595 1.595-1.595h50.177a1.595 1.595 0 010 3.19H16.94c-.88 0-1.595-.714-1.595-1.595zM15.345 34.629c0-.881.714-1.595 1.595-1.595h40.957a1.595 1.595 0 010 3.19H16.94c-.88 0-1.595-.714-1.595-1.595z"
                fill="#412ECC"
            />
            <path
                d="M4.663 39.292a4.663 4.663 0 100-9.327 4.663 4.663 0 000 9.327z"
                fill="#E31C46"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.258 4.682A4.682 4.682 0 0116.94 0h60.32a4.682 4.682 0 010 9.364H16.94a4.682 4.682 0 01-4.682-4.682z"
                fill="#4CD6C5"
            />
        </svg>
    )
}

export default IllustrationOrder
