import React, { useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import { Modal, Text } from "../../components";
import colors from '../../utils/colors';

const Competences = React.forwardRef(({ }, ref) => {
    const [items, setItems] = useState([
        { label: "Adaptability", value: "" },
        { label: "Comunication", value: "" },
        { label: "Risk management", value: "" },
        { label: "Discipline", value: "" },
        { label: "Facilitation", value: "" },
        { label: "Motivation", value: "" },
        { label: "Resilience", value: "" },
        { label: "Polyvalence", value: "" },
        { label: "Leadership", value: "" },
        { label: "Negotiation", value: "" },
        { label: "Coaching", value: "" },
        { label: "Team Lead", value: "" }
    ]);

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return items.map((item) => ({ competence: item.label.toUpperCase().replace(" ", "_"), value: parseInt(item.value) || 0 }));
        }
    }), []);

    const [isHelpOpen, setIsHelpOpen] = useState(true);

    return (
        <>
            <Wrapper>
                <Text maxWidth="452px" align="center" color={colors.grey01} size={32} height={40} style={{ marginTop: 84, marginBottom: 12 }}>Ready to evaluate your skills?</Text>
                <Text maxWidth="452px" align="center" color={colors.grey03} size={14} height={28}>Rate them from 1 to 10.</Text>
                <Center>
                    <Boxes>
                        {items.map((item, index) => (
                            <Box key={item.label}>
                                <Input
                                    value={item.value}
                                    onChange={(e) => { items[index].value = e.target.value; setItems([...items]); }}
                                    onBlur={() => {
                                        const number = parseInt(items[index].value);
                                        if (isNaN(number) || (number <= 0 || number > 10)) {
                                            items[index].value = "";
                                            setItems([...items]);
                                        } else if (items[index].value != number) {
                                            items[index].value = number;
                                            setItems([...items]);
                                        }
                                    }}
                                />
                                <Text color={colors.grey01} size={16} height={24}>{item.label}</Text>
                            </Box>
                        ))}
                    </Boxes>
                </Center>
            </Wrapper>
            <Modal isOpen={isHelpOpen} onRequestClose={() => setIsHelpOpen(false)}>
                <div style={{ marginBottom: 12 }}><Text color={colors.yellow01} size={32} height={44} align="center" width={"452px"}>Step 03 - Skills</Text></div>
                <div style={{ marginBottom: 8 }}>
                    <Text color={colors.grey01} size={24} height={32} align="center" width={"492px"}>
                        The purpose of this self-assessment is to help us know the extent of your abilities and to improve upon them. On a scale of 1 to 10, where 1 is dreadful and 10 is excellent, rate yourself on each of these skills.
                    </Text>
                </div>
            </Modal>
        </>
    );
});

const Wrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: column;
    background-color: white;
`;

const Center = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 90px;
`;

const Boxes = styled.div`
    width: 1160px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row;
    flex-wrap: wrap;
    margin: -8px -10px;
`;

const Box = styled.div`
    position: relative;
    height: 120px;
    width: 216px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    flex-flow: column;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    margin: 8px 10px;
`;

const Input = styled.input`
    margin-bottom: 12px;
    border: none;
    border-bottom: 1px solid ${colors.grey03};
    color: ${colors.yellow01};
    text-align: center;
    font-size: 32px;
    line-height: 44px;
    height: 44px;
    width: 120px;
`;

export default Competences;