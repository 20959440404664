import React from "react";
import styled from "styled-components";
import colors from "../../../../utils/colors";

export default function Button(
    {
        label,
        color = colors.grey01,
        disabled,
        size = 52,
        width,
        children,
        ...props
    }
) {
    let hover = color;

    if (color === colors.grey01) {
        hover = colors.grey02;
    } else if (color === colors.yellow01) {
        hover = colors.yellow03;
    }

    return (
        <Btn
            width={width}
            disabled={!!disabled}
            color={color}
            hover={hover}
            size={size}
            {...props}
        >
            {label}
        </Btn>
    );
}

const Btn = styled.button`
    font-family: "Aeonik-Regular";
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    width: ${({ width }) => !!width ? width : "fit-content"};
    border-radius: 26px;
    border: none;

    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;

    background-color: ${({ color }) => color};
    color: ${colors.white01};

    height: ${({ size }) => size}px;
    padding: 14px 32px;

    :disabled {
        cursor: auto;
    }
    
    :hover {
        background-color: ${({ hover }) => hover};
    }
`;