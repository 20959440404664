import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from 'react';
import ReactLoading from 'react-loading';
import { useParams } from "react-router-dom";
import styled from 'styled-components';
import { SvgIconError, SvgLogo } from '../../assets/svgs';
import { Stepper, Text } from '../../components';
import colors from '../../utils/colors';
import '../../utils/yupMessages';
import AlreadySubmitted from '../AlreadySubmitted';
import Competences from '../Competences';
import Cv from '../Cv';
import Entrance from '../Entrance';
import Finish from '../Finish';
import MovingMotivators from '../MovingMotivators';
import PersonalMap from '../PersonalMap';
import VideoUpload from '../VideoUpload';

function App() {

    const { token } = useParams();

    const [queryCandidatureEvidenceMatchValid] = useLazyQuery(
        gql`
            query candidatureEvidenceMatchValid($token: String!) {
                candidatureEvidenceMatchValid(token: $token)
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data) {
                    if (!data.candidatureEvidenceMatchValid) {
                        setAlreadySubmitted(true);
                    }
                    setTimeout(() => setLoading(false), 2000);
                }
            }
        }
    );

    const [mutateCandidatureEvidenceMatchSend] = useMutation(
        gql`
            mutation candidatureEvidenceMatchSend(
                $token: String!,
                $personalName: String,
                $personalNationality: String,
                $personalBirthdate: String,
                $personalGender: String,
                $personalQualifications: String,
                $contactsEmail: String,
                $contactsPhone: String,
                $contactsLocation: String,
                $interviewDetails: String,
                $interviewPersonalDevelopment: String,
                $interviewSalaryExpectations: String,
                $professionalExperience: Json,
                $teachingExperience: Json,
                $education: Json,
                $otherCourses: Json,
                $technicalSkills: Json,
                $personalMapBlob: Upload!,
                $personalMapSchema: Json!,
                $movingMotivators: [CandidatureMovingMotivators!]!,
                $movingMotivatorsBlob: Upload!,
                $competences: [CandidatureCompetenceInput!]!,
                $video: Upload
            ) {
                candidatureEvidenceMatchSend(
                    token: $token,
                    personalName: $personalName,
                    personalNationality: $personalNationality,
                    personalBirthdate: $personalBirthdate,
                    personalGender: $personalGender,
                    personalQualifications: $personalQualifications,
                    contactsEmail: $contactsEmail,
                    contactsPhone: $contactsPhone,
                    contactsLocation: $contactsLocation,
                    interviewDetails: $interviewDetails,
                    interviewPersonalDevelopment: $interviewPersonalDevelopment,
                    interviewSalaryExpectations: $interviewSalaryExpectations,
                    professionalExperience: $professionalExperience,
                    teachingExperience: $teachingExperience,
                    education: $education,
                    otherCourses: $otherCourses,
                    technicalSkills: $technicalSkills,
                    personalMapBlob: $personalMapBlob,
                    personalMapSchema: $personalMapSchema,
                    movingMotivators: $movingMotivators,
                    movingMotivatorsBlob: $movingMotivatorsBlob,
                    competences: $competences,
                    video: $video
                )
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.candidatureEvidenceMatchSend) {
                    setTimeout(async () => {
                        setFinish(true);
                    }, 1500);

                    setTimeout(() => setLoading(false), 3000);
                }
            }
        }
    );

    const cvRef = useRef(null);
    const personalMapRef = useRef(null);
    const movingMotivatorsRef = useRef(null);
    const competencesRef = useRef(null);
    const videoRef = useRef(null);

    const [loading, setLoading] = useState(true);
    const [alreadySubmitted, setAlreadySubmitted] = useState(false);
    const [showFillError, setShowFillError] = useState(false);
    const [entrance, setEntrance] = useState(true);
    const [finish, setFinish] = useState(false);

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        queryCandidatureEvidenceMatchValid({ variables: { token } });
    }, []);

    return (
        <>
            <Wrapper>
                <Stepper
                    currentIndex={currentIndex}
                    screens={[
                        { title: "Curriculum", screen: <Cv onSubmit={() => setCurrentIndex(currentIndex + 1)} ref={cvRef} /> },
                        { title: "Personal Map", screen: <PersonalMap ref={personalMapRef} /> },
                        { title: "Moving Motivators", screen: <MovingMotivators ref={movingMotivatorsRef} /> },
                        { title: "Competences", screen: <Competences ref={competencesRef} /> },
                        { title: "Video", screen: <VideoUpload ref={videoRef} /> }
                    ]}
                    onClickPrevious={() => { setCurrentIndex(currentIndex - 1); setShowFillError(false); }}
                    onClickNext={() => {
                        if (currentIndex === 0) {
                            cvRef.current.validate();
                            return;
                        }

                        if (currentIndex === 3) {
                            const competencesValue = competencesRef.current.getValue();
                            const isSomethingEmpty = !!competencesValue.find((item) => item.value === 0);

                            if (isSomethingEmpty) {
                                setShowFillError(true);
                                return;
                            }
                        }

                        setShowFillError(false);
                        setCurrentIndex(currentIndex + 1);
                    }}
                    onClickSave={async () => {
                        setLoading(true);

                        const cvValue = cvRef.current.getValue();
                        const personalMapValue = await personalMapRef.current.getValue();
                        const movingMotivatorsValue = await movingMotivatorsRef.current.getValue();
                        const competencesValue = competencesRef.current.getValue();
                        const videoValue = videoRef.current.getValue();

                        mutateCandidatureEvidenceMatchSend({
                            variables: {
                                token,
                                ...cvValue,
                                personalMapBlob: personalMapValue.blob,
                                personalMapSchema: personalMapValue.schema,
                                movingMotivatorsBlob: movingMotivatorsValue.blob,
                                movingMotivators: movingMotivatorsValue.items,
                                competences: competencesValue,
                                video: videoValue
                            }
                        })
                    }}
                />
                <ShowHideContainer show={alreadySubmitted}>
                    <AlreadySubmitted />
                </ShowHideContainer>
                <ShowHideContainer show={!alreadySubmitted && entrance}>
                    <Entrance onClickStart={() => setEntrance(false)} />
                </ShowHideContainer>
                <ShowHideContainer show={finish}>
                    <Finish />
                </ShowHideContainer>
                <ShowHideContainer show={loading}>
                    <ReactLoading type="spin" color={colors.yellow01} height={30} width={30} />
                </ShowHideContainer>
                <Logo>
                    <SvgLogo />
                </Logo>
            </Wrapper>
            {showFillError && <ErrorWrapper>
                <div style={{ marginRight: 12 }}>
                    <SvgIconError />
                </div>
                <Text color={colors.grey01} size={16} height={24}>
                    All fields need to be filled in order to proceed
                </Text>
            </ErrorWrapper>}
        </>
    );
}

const Wrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    background-color: white;
    min-width: 1366px;
    min-height: 768px;
`;

const Logo = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: ${colors.white01};
    padding-left: 22px;
    padding-top: 20px;
    padding-bottom: 20px;
`;

const ShowHideContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;

    visibility: ${({ show }) => show ? "visible" : "hidden"};
    opacity: ${({ show }) => show ? 1 : 0};
    transition: visibility 1s linear, opacity 1s linear;
`;

const ErrorWrapper = styled.div`
    position: absolute;
    height: 48px;
    width: fit-content;
    right: 22px;
    bottom: 92px;
    padding: 12px 16px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    background-color: white;
`;


export default App;