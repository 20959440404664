import React from "react";
import styled from "styled-components";
import { SvgIconClose } from "../../../../../assets/svgs";
import colors from "../../../../../utils/colors";
import Block from "../../Block";
import Button from "../../Button";
import FieldArray from "../../FieldArray";
import FormGroup from "../../FormGroup";
import Input from "../../Input";
import Portlet from "../../Portlet";
import Text from "../../Text";
import TextInput from "../TextInput";

export default function ListEducation({ value = [] }) {
    return (
        <FieldArray
            name={"education"}
            render={(arrayHelpers) => {
                return (
                    <Block>
                        <Text size={24} height={32} color={colors.grey01} mb={24}>Education</Text>
                        {value.map((_item, index) => {
                            return (
                                <Portlet
                                    key={index}
                                >
                                    <ButtonDelete center middle onClick={() => arrayHelpers.remove(index)}>
                                        <SvgIconClose />
                                    </ButtonDelete>
                                    <FormGroup>
                                        <Block width={688}>
                                            <Input Component={TextInput} name={`education[${index}].course`} label="COURSE" placeholder="Course of studies" />
                                        </Block>
                                        <Block row>
                                            <Block width={566} mr={20}>
                                                <Input Component={TextInput} name={`education[${index}].institution`} label="INSTITUITION" placeholder="Institution of study" />
                                            </Block>
                                            <Block width={216}>
                                                <Input Component={TextInput} name={`education[${index}].conclusion`} label="CONCLUSION" placeholder="Year of conclusion" />
                                            </Block>
                                        </Block>
                                    </FormGroup>
                                </Portlet>
                            );
                        })}
                        <Block>
                            <Button type="button" width="216px" label="Add education" color={colors.grey01} onClick={() => arrayHelpers.insert(value.length, {})} />
                        </Block>
                    </Block>
                );
            }}
        />
    );
}

const ButtonDelete = styled(Block)`
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    right: 12px;
    border-radius: 12px;
    background: ${colors.grey01};
    cursor: pointer;
`;