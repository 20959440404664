import * as React from "react"

function IllustrationMastery(props) {
    return (
        <svg
            width={54}
            height={89}
            viewBox="0 0 54 89"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 1.595C0 .715.714 0 1.595 0h50.027c.88 0 1.595.714 1.595 1.595v13.767c0 14.694-11.914 26.608-26.608 26.608C11.913 41.97 0 30.056 0 15.362V1.595zM3.19 3.19v12.172c0 12.932 10.486 23.418 23.419 23.418 12.932 0 23.418-10.486 23.418-23.418V3.19H3.19z"
                fill="#412ECC"
            />
            <path
                d="M41.558 88.799H11.655a3.433 3.433 0 01-3.433-3.434v-22.83a3.433 3.433 0 013.433-3.433h29.903a3.433 3.433 0 013.434 3.433v22.83a3.437 3.437 0 01-3.434 3.434z"
                fill="#4CD6C5"
            />
            <path
                d="M26.605 80.748a6.8 6.8 0 100-13.6 6.8 6.8 0 000 13.6z"
                fill="#E31C46"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.605 39.52c.881 0 1.595.714 1.595 1.595v17.987a1.595 1.595 0 11-3.19 0V41.115c0-.88.714-1.595 1.595-1.595z"
                fill="#412ECC"
            />
        </svg>
    )
}

export default IllustrationMastery
