import * as React from "react"

function IconArrowLeft({ color = "#444", ...props }) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8.75 13.75L5 10l3.75-3.75M5 10h11.25"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default IconArrowLeft
