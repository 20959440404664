import * as React from "react"

function IllustrationPower(props) {
    return (
        <svg
            width={63}
            height={68}
            viewBox="0 0 63 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M29.516.784L61.1 29.744c1.34 1.23 1.277 3.363-.127 4.523-9.323 7.707-23.14 7.553-32.309-.853-9.17-8.407-10.513-22.157-3.64-32.113 1.033-1.497 3.153-1.747 4.493-.517z"
                fill="#4CD6C5"
            />
            <path
                d="M17.396 67.753c-.336 0-.676-.1-.973-.31-.237-.166-5.833-4.19-10.453-11.46-4.297-6.763-8.497-17.696-4.113-31.389l.06-.157 5.15-11.863a3.048 3.048 0 013.213-1.67l22.923 3.534a3.071 3.071 0 012.566 3.453c-.126.89-.8 3.966-4.17 6.263-3.243 2.21-7.953 3.04-14.01 2.473l-.76 3.397c2.437 2.143 5.677 5.833 5.417 10.12a1.675 1.675 0 01-1.78 1.576 1.682 1.682 0 01-1.576-1.78c.21-3.496-3.76-6.993-4.98-7.98l-.81-.656 1.87-8.377 1.52.194c5.873.746 10.446.143 13.216-1.747 1.924-1.31 2.503-2.897 2.677-3.66l-22.387-3.45-4.963 11.433c-3.166 9.97-1.896 19.553 3.777 28.48 4.27 6.723 9.51 10.486 9.563 10.523a1.682 1.682 0 01-.977 3.053z"
                fill="#412ECC"
            />
            <path
                d="M26.49 19.944a4.943 4.943 0 100-9.886 4.943 4.943 0 000 9.886z"
                fill="#E31C46"
            />
        </svg>
    )
}

export default IllustrationPower
