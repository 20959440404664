import * as React from "react"

function IconNode(props) {
    return (
        <svg
            width={58}
            height={22}
            viewBox="0 0 58 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect x={1} y={1} width={56} height={20} rx={4} stroke="#fff" />
        </svg>
    )
}

export default IconNode
