import * as React from "react"

function IllustrationRelatedness(props) {
    return (
        <svg
            width={84}
            height={71}
            viewBox="0 0 84 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M61.187 70.626c-.847 0-1.674-.33-2.3-.956l-10.09-10.09H6.923C3.107 59.583 0 56.477 0 52.66V20.377c0-3.817 3.107-6.924 6.923-6.924h50.6c3.817 0 6.924 3.107 6.924 6.924V67.37c0 1.32-.79 2.503-2.01 3.006-.407.17-.83.25-1.25.25zM6.923 16.646A3.738 3.738 0 003.19 20.38v32.28a3.738 3.738 0 003.733 3.733H50.12L61.147 67.42c.023.023.03.03.07.013.04-.017.04-.027.04-.06V20.376a3.738 3.738 0 00-3.734-3.733h-50.6v.003z"
                fill="#412ECC"
            />
            <path
                d="M78.097 42.937h-50.6a5.327 5.327 0 01-5.327-5.327V5.327A5.327 5.327 0 0127.497 0h50.6a5.327 5.327 0 015.326 5.327v32.28a5.33 5.33 0 01-5.326 5.33z"
                fill="#4CD6C5"
            />
            <path
                d="M73.76 15.4a4.663 4.663 0 100-9.327 4.663 4.663 0 000 9.327z"
                fill="#E31C46"
            />
            <path
                d="M80.59 53.493l-13.16-13.16 15.993-6.163v18.147c0 1.483-1.79 2.223-2.833 1.176z"
                fill="#4CD6C5"
            />
        </svg>
    )
}

export default IllustrationRelatedness
